.vendorlocator {
    .tag {
        display: inline-block;
        padding: 0.2rem 0.8rem;
        border-radius: 2px;
        font-size: 0.8rem;
        &:not(:last-child) {
            margin-right: 1rem;
        }
        &,
        & * {
            cursor: pointer;
        }
    }
    .legend {
        margin: 2rem 0;
        background: rgba(0, 0, 0, 0.1);
        padding: 2rem;
    }
    .functional.block {
        display: grid;
        grid-template-columns: 1fr 1fr;
        border: none;
        margin: 1rem 0 1rem 0;
        .tag {
            cursor: pointer;
        }
    }
    &.modal {
        .thirty-seventy {
            padding: 0;
            &>label {
                font-weight: bold;
                vertical-align: middle;
                align-self: center;
            }
        }
        .category-grid {
            line-height: 2.5rem;
            input[type=checkbox] {
                vertical-align: middle;
                margin-right: 0.5rem !important;
                display: inline-block;
            }
            label {
                margin: 0;
            }
            .tag {
                line-height: 1.4rem;
            }
        }
    }
    .leaflet-container {
        height: 400px;
        .leaflet-control-attribution {
            display: none;
        }
    }
    table.sortable {
        tr {
            border-bottom: 1px solid snow;
            td {
                white-space: none;
            }
        }
        .tag {
            font-size: 0.7rem;
            cursor: default;
        }
    }
    .noresult {
        font-size: 3rem;
        background: rgba(0, 0, 0, 0.1);
        text-align: center;
        padding: 8rem 0;
        svg {
            display: block;
            margin: 1rem auto;
            font-size: 6rem;
        }
    }
}